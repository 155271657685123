import React, { useEffect, useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import styled from "styled-components"
import SEO from "../components/seo"
import { Container } from "../components/styledComponents"
import { BigMobileFont } from "../components/typography"
import SocialIcon from "../components/socialIcon"
import LastCTASection from "../components/lastCTASection"
import BaseImg from "../components/baseImg"
import NewsletterForm from "../components/newsletterForm"
import CenterBorderedHeader from "../components/centerBorderedHeader"
import ArticlesGrid from "../components/articlesGrid"

const ArticleInfoBox = styled.div`
  box-shadow: 0px 3px 6px #00000029;
  padding: 36px 52px;
  background: #fff;
  font-size: 1.125rem;
  max-width: 580px;
`

const HeroSection = styled.div`
  min-height: calc(100vh - 160px);
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  @media (max-width: 900px) {
    min-height: auto;
    flex-direction: column;
  }
`

const HeroImg = styled(BaseImg)`
  position: relative;
  z-index: -1;
  width: 60%;
  margin: 0 -20% 0 0;
  @media (max-width: 900px) {
    width: 100%;
    margin: 0 0 -20% 0;
  }
  @media (max-width: 768px) {
    margin: 0;
  }
`

const ArticleContent = styled.div`
  line-height: 1.625;
  margin-bottom: 100px;

  @media (max-width: 991px) {
    margin-bottom: 3rem;
  }
  h2 {
    font-size: 1.5rem;
    margin: 3.25rem 0 0.75rem;
    scroll-margin-top: 105px;
  }
  h3 {
    font-size: 1.125rem;
    margin: 3.25rem 0 0.75rem;
  }
  img {
    width: 100%;
    margin: 40px 0;
    object-fit: contain;
  }
  .wp-block-columns {
    display: flex;
  }
  .wp-block-column + .wp-block-column {
    margin-left: 2rem;
  }
  .wp-block-table {
    overflow-x: scroll;
  }

  .caseStudy {
    display: flex;
    margin: 10rem 0;
    position: relative;

    @media (max-width: 767px) {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      margin: 4rem 0 0;
    }

    &ImageWrapper {
      position: absolute;
      top: 50%;
      right: 70%;
      transform: translateY(-50%);
      height: 44rem;
      width: 44rem;

      @media (max-width: 767px) {
        position: static;
        transform: translateY(0);
        width: 100%;
        height: auto;
      }

      > img {
        height: 100%;
        width: 100%;
        margin: 0;
        object-fit: contain;
        object-position: right center;
      }
    }

    &Content {
      position: relative;
      padding: 1.75rem 1rem 1.75rem 0;
      max-width: 70%;
      margin-left: auto;

      @media (max-width: 767px) {
        max-width: 100%;
      }
    }

    &Title {
      font-size: 2.5rem;
      line-height: 3.0625rem;
      margin: 0 0 0.5rem;
    }

    &Description {
      font-size: 1.125rem;
      line-height: 1.8125rem;
      margin-bottom: 1.25rem;
    }

    &Logos {
      display: flex;
      column-gap: 16px;
      row-gap: 8px;
      flex-wrap: wrap;
      margin-bottom: 2.0625rem;
    }

    &Logo {
      flex-basis: 30%;
      height: 52px;

      > img {
        margin: 0;
        object-fit: contain;
      }
    }

    &Button {
      display: inline-block;
      min-width: 375px;
      position: relative;
      text-align: center;
      line-height: 46px;
      width: fit-content;
      font-family: "Montserrat";
      font-size: 18px;
      @media (max-width: 1195px) {
        font-size: 1rem;
      }
      border: none;
      color: #fff;
      font-weight: bold;
      background: ${(props) => props.theme.secondary};
      box-shadow: 0px 3px 6px #00000029;
      cursor: pointer;
      border: 1px solid ${(props) => props.theme.secondary};
      transition: color 0.1s ease-in-out, background 0.2s ease-in-out;
      :focus {
        border-style: dashed;
        background: #fff;
        color: ${(props) => props.theme.secondary};
        outline: none;
      }
      :hover {
        color: ${(props) => props.theme.secondary};
        background: #fff;
      }
    }

    &BorderBox {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 70%;
      height: calc(100% + 74px);
      border: 1px solid ${(props) => props.theme.secondary};
      border-left: none;
      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
        height: 24px;
        border-left: 1px solid ${(props) => props.theme.secondary};
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }
    }
  }
`

const ShortcutList = styled.ul`
  list-style: none;
  margin-left: 12px;
`
const ShortcutListElement = styled.li`
  position: relative;
  ::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -12px;
    transform: translateY(-50%);
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: ${(props) => props.theme.secondary};
    font-weight: bold;
    display: inline-block;
  }

  > a {
    font-size: 16px;
    font-weight: bold;
    line-height: 29px;
  }
`

const ArticleContainer = styled.div`
  margin: 0 auto;
  max-width: 100%;
  padding: 2rem;
  display: grid;
  grid-template-columns: 1fr 376px;
  column-gap: 45px;
  grid-template-areas:
    "article form"
    "buttons buttons";
  @media (min-width: 1300px) {
    padding: 0;
    max-width: 1250px;
  }

  @media (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "article article"
      "form form"
      "buttons buttons";
  }
`

const TextContainer = styled.div`
  grid-area: article;
`

const FormContainer = styled.div`
  max-width: 376px;
  grid-area: form;
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`

const ButtonContainer = styled.div`
  grid-area: buttons;
  display: flex;
  justify-content: space-between;
  font-weight: 700;
`

const ShortcutTitle = styled.h2`
  font-size: 18px;
  line-height: 22px;
`

const SingleArticle = ({ pageContext }) => {
  const { wordpressPage, allWordpressPost } = useStaticQuery(graphql`
    query {
      wordpressPage(
        template: { eq: "page-templates/page-global-settings.php" }
      ) {
        cmb2 {
          metabox_global_settings_templates {
            articleShare
            articlePrevious
            articleNext
            articleLastCTASectionTitle
            articleLastCTASectionText
            articleLastCTASectionButtonUrl
            articleLastCTASectionButtonText
          }
          metabox_global_settings_shortcut_links {
            blogShortcutTitle
          }
          metabox_global_settings_related_articles {
            relatedArticlesTitle
          }
        }
      }
      allWordpressPost(sort: { fields: date, order: DESC }) {
        nodes {
          title
          excerpt
          path
          featured_media {
            localFile {
              childImageSharp {
                fixed(height: 215, width: 330) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          categories {
            name
            wordpress_id
          }
          tags {
            name
            wordpress_id
          }
        }
      }
    }
  `)
  const {
    articleShare,
    articlePrevious,
    articleNext,
    articleLastCTASectionTitle,
    articleLastCTASectionText,
    articleLastCTASectionButtonUrl,
    articleLastCTASectionButtonText,
  } = wordpressPage.cmb2.metabox_global_settings_templates
  const {
    blogShortcutTitle,
  } = wordpressPage.cmb2.metabox_global_settings_shortcut_links
  const {
    relatedArticlesTitle,
  } = wordpressPage.cmb2.metabox_global_settings_related_articles
  const { nodes } = allWordpressPost

  const {
    title,
    content,
    author,
    featured_media,
    date,
    categories,
    socialIcons,
    next,
    previous,
    yoast_meta,
    path,
  } = pageContext
  const facebookButton = socialIcons.find(
    (file) => file.name === "facebookButton"
  )
  const linkedinButton = socialIcons.find(
    (file) => file.name === "linkedinButton"
  )

  const [links, setLinks] = useState([])
  const [relatedArticles, setRelatedArticles] = useState([])

  const filterRelatedArticles = () => {
    const categoriesName = [...categories].map((category) => category.name)
    const filteredRelatedArticles = nodes.filter((article) => {
      if (!article.categories) return false
      return article.categories.some(
        (category) => categoriesName.includes(category.name)
        // filters[variant].includes(element.wordpress_id)
      )
    })
    const newRelatedArticles = filteredRelatedArticles.splice(0, 3)
    setRelatedArticles(newRelatedArticles)
  }

  const getShortcutLinks = () => {
    const titlesEl = [...document.querySelectorAll(".article-content h2")]

    titlesEl.forEach((el, index) => {
      el.setAttribute("id", `title-${index}`)
    })

    const newLinks = titlesEl.map((element, index) => {
      return { text: element.textContent, url: `#title-${index}` }
    })
    setLinks(newLinks)
  }

  useEffect(() => {
    getShortcutLinks()
    filterRelatedArticles()
  }, [])

  return (
    <Layout>
      <SEO
        meta={yoast_meta}
        title={title}
        image={featured_media?.localFile?.publicURL}
        path={path}
      />
      <Container size="medium">
        <HeroSection>
          <HeroImg alt="" localFile={featured_media.localFile} />
          <ArticleInfoBox>
            {date} {categories.map((category) => "| " + category.name)}
            <h1 style={{ margin: "0.75rem 0" }}>
              <BigMobileFont>{title}</BigMobileFont>
            </h1>
            {author.name}
          </ArticleInfoBox>
        </HeroSection>
      </Container>
      <ArticleContainer>
        <TextContainer>
          <ShortcutTitle>{blogShortcutTitle}</ShortcutTitle>
          <ShortcutList>
            {links.map(({ text, url }) => (
              <ShortcutListElement key={text + url}>
                <a href={url}>{text}</a>
              </ShortcutListElement>
            ))}
          </ShortcutList>
          <ArticleContent
            className="article-content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </TextContainer>
        <FormContainer>
          <NewsletterForm />
          <div
            style={{
              fontWeight: "700",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "3rem",
            }}
          >
            {articleShare}:
            <div style={{ height: "34px" }}>
              <SocialIcon
                style={{ margin: "0 -10px 0 10px" }}
                src={facebookButton.publicURL}
                alt="Facebook logo"
                rel="noopener noreferrer"
                url={`https://www.facebook.com/sharer/sharer.php?u=${path}`}
              />
              <SocialIcon
                src={linkedinButton.publicURL}
                alt="LinkedIn logo"
                rel="noopener noreferrer"
                url={`https://www.linkedin.com/shareArticle?mini=true&url=${path}&title=${title}`}
              />
            </div>
          </div>
        </FormContainer>
        <ButtonContainer>
          {previous ? (
            <Link to={previous.path}>{`< ${articlePrevious}`}</Link>
          ) : (
            <div />
          )}
          {next ? <Link to={next.path}>{`${articleNext} >`}</Link> : <div />}
        </ButtonContainer>
      </ArticleContainer>
      <div style={{ margin: "130px 0" }}>
        <Container>
          <CenterBorderedHeader>
            <BigMobileFont desktopFontSize="2.5rem">
              {relatedArticlesTitle}
            </BigMobileFont>
          </CenterBorderedHeader>
          <ArticlesGrid posts={relatedArticles} />
        </Container>
      </div>
      <LastCTASection
        title={articleLastCTASectionTitle}
        text={articleLastCTASectionText}
        buttonText={articleLastCTASectionButtonText}
        buttonUrl={articleLastCTASectionButtonUrl}
      />
    </Layout>
  )
}

export default SingleArticle
