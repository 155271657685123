import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { useNewsletterForm } from "./hooks/useNewsletterForm"
import LoadingSpinner from "./loadingSpinner"

const Wrapper = styled.div`
  width: 100%;
  padding: 30px;
  margin-bottom: 2rem;
  box-shadow: 0px 3px 6px #00000029;
`
const WrapperTitle = styled.div`
  margin-bottom: 20px;
`

const WrapperForm = styled.div``

const Title = styled.h6`
  font-size: 18px;
  line-height: 22px;
  text-align: center;
`

const InputText = styled.input`
  all: unset;
  width: 100%;
  padding: 9px 18px;
  box-sizing: border-box;
  font-size: 1rem;
  box-shadow: 0px 3px 6px #00000029;
  ::placeholder {
    opacity: 1;
  }
`

const CheckboxWrapper = styled.div`
  display: flex;
  margin: 20px 0;

  > input {
    display: inline-block;
    width: 0.6rem;
    opacity: 0;
  }
  > label {
    position: relative;
    margin-left: 16px;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    :hover {
      color: ${(props) => props.theme.secondary};
    }
    ::before {
      content: "";
      position: absolute;
      top: 5px;
      left: -17px;
      z-index: 1;
      width: 11px;
      height: 11px;
      outline: 1px solid ${(props) => props.theme.secondary};
    }
  }
  > input:checked + label::before {
    background-color: ${(props) => props.theme.secondary};
  }
`

const Button = styled.button`
  display: inline-block;
  width: 100%;
  position: relative;
  text-align: center;
  line-height: 39px;
  font-family: "Montserrat";
  font-size: 18px;
  padding: 0.3rem 0.7rem;
  border: none;
  color: #fff;
  font-weight: bold;
  background: ${(props) => props.theme.secondary};
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.secondary};
  transition: color 0.1s ease-in-out, background 0.2s ease-in-out;

  :focus {
    border-style: dashed;
    background: #fff;
    color: ${(props) => props.theme.secondary};
    outline: none;
  }
  :hover {
    color: ${(props) => props.theme.secondary};
    background: #fff;
  }
`

const StyledResponse = styled.div`
  text-align: center;
  font-weight: bold;
  margin-top: 1rem;
  color: ${(props) =>
    props.result === true ? "#007E33" : props.theme.secondary};
`

const NewsletterForm = () => {
  const { wordpressPage, site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          adminSiteUrl
        }
      }
      wordpressPage(
        template: { eq: "page-templates/page-global-settings.php" }
      ) {
        cmb2 {
          metabox_global_settings_newsletter_form {
            newsletterTitle
            newsletterInputPlaceholder
            newsletterConsent
            newsletterButtonName
            newsletterError
            newsletterSuccess
          }
        }
      }
    }
  `)

  const {
    newsletterTitle,
    newsletterInputPlaceholder,
    newsletterConsent,
    newsletterButtonName,
    newsletterError,
    newsletterSuccess,
  } = wordpressPage.cmb2.metabox_global_settings_newsletter_form

  const {
    email,
    responseMessage,
    handleEmail,
    isLoading,
    handleSubmit,
  } = useNewsletterForm(newsletterSuccess, newsletterError, site)

  return (
    <Wrapper>
      <WrapperTitle>
        <Title>{newsletterTitle}</Title>
      </WrapperTitle>
      <WrapperForm>
        <form action="" onSubmit={(event) => handleSubmit(event)}>
          <div>
            <InputText
              id="form-email"
              type="email"
              required
              placeholder={newsletterInputPlaceholder}
              onChange={(event) => handleEmail(event)}
              value={email}
            />
          </div>
          <CheckboxWrapper>
            <input
              type="checkbox"
              required
              name="privacy-policy"
              id="privacy-policy"
            />
            <label htmlFor="privacy-policy">{newsletterConsent}</label>
          </CheckboxWrapper>
          <Button type="submit">{newsletterButtonName}</Button>
          {isLoading ? <LoadingSpinner /> : null}
          <StyledResponse result={responseMessage.sent}>
            {responseMessage.text}
          </StyledResponse>
        </form>
      </WrapperForm>
    </Wrapper>
  )
}

export default NewsletterForm
